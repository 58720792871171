.hp-banner {
	position: relative;
	top: 1px;

	&__item {
		width: 100%;
		height: auto;
	}

	&__info {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 15px;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;

		& > * {
			margin-top: 0;
			margin-bottom: 0;
		}

		& > * + * {
			margin-top: 10px;
		}
	}

	&__caption-with-icon {
		display: inline-flex;
		align-items: center;
		color: $color-whitish;
		min-width: 310px;

		.icon {
			margin-inline-end: 13px;
			flex-shrink: 0;
		}
	}

	&__text {
		@include font-secondary;
		line-height: 16px;
	}

	.swiper-pagination {
		padding-top: 8px;
	}

	.swiper-pagination-bullet {
		margin: 0 3px;
	}

	a {
		color: white;
	}
}

.hp-banner-lg {
	&__row {
		display: flex;
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;
		width: 100%;
		overflow: hidden;
	}

	&__left,
	&__right {
		max-width: 580px;
	}

	&__middle {
		position: relative;
		flex-shrink: 0;
	}

	&__info {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 20px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-evenly;

		& > * {
			margin-top: 0;
			margin-bottom: 0;
		}

		& > * + * {
			margin-top: 13px;
		}
	}

	&__caption-with-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: $color-whitish;
		text-align: center;

		.icon {
			margin-block-end: 8px;
			flex-shrink: 0;
		}
	}

	&__text {
		@include font-secondary;
		line-height: 16px;
	}

	.swiper-button-next {
		filter: drop-shadow(-1px 0 10px #000);
	}

	.swiper-button-prev {
		filter: drop-shadow(1px 0 10px #000);
	}

	.swiper-pagination {
		padding: 15px 0 0 0;
		display: none;
	}

	.swiper-pagination-bullet {
		margin: 0 3px;
	}

	a {
		color: #fff;
	}
}
