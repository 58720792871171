.product-item {
	width: 100%;
	margin: 0 0 80px;
	float: left;
	overflow: hidden;
	position: relative;
	cursor: default;
	border-right: 1px solid #e6e6e6;
	padding: 0;

	.product-item-original {
		display: block;
		position: relative;
		margin: 0px;
		padding: 0 30px;
		overflow: hidden;
		@include transition(all, 0.3s, ease-in-out);

		img {
			max-height: 215px;
		}

		h2 {
			height: 42px;
			line-height: 20px;
			font-size: 17px;
			font-weight: 700;
			overflow: hidden;
			margin-top: 10px;
			font-family: $font-family-sans-serif;

			@media (max-width: $screen-xs-max) {
				line-height: 17px;
				margin-top: 5px;
				margin-bottom: 5px;
				height: 52px;
			}

			a {
				color: #3b3832;
			}
		}

		.product-item-desc {
			height: 58px;
			font-size: 14px;
			max-height: 58px;
			overflow: hidden;
		}

		.price-row {
			display: block;
			margin-top: 10px;
			margin-bottom: 10px;
			line-height: 14px;
			height: 50px;
			max-height: 50px;

			.product-item-price {
				font-size: 21px;
				line-height: 21px;
				font-weight: 700;
				position: absolute;
				bottom: 24px;

				sup {
					font-size: 60%;
					top: -0.5em;
				}

				.tg-price-before {
					font-size: 12px;
					line-height: 14px;
					text-decoration: line-through;
					font-weight: 400;
					display: block;
				}
			}

			.product-item-availability {
				font-size: 12px;
				display: block;
				position: absolute;
				bottom: 10px;

				@media (min-width: $screen-sm-min) {
					right: 30px;
					bottom: 24px;
				}
			}
		}

		.product-item-overlay {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: none;
			background-color: rgba(145, 124, 78, .1);


			& > a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		&:hover {
			.product-item-overlay {
				display: block;
			}
		}

		.overlay-actions {
			position: absolute;
			top: 185px;
			left: 0;

			.add-to-cart {
				border-left: 1px solid #fff;
			}
		}

		.toggle-favorite {
			position: absolute;
			top: 5px;
			right: 0;
			color: #aaa;

			&:hover {
				color: $brand-primary;
			}
		}

		& > .fa-heart {
			position: absolute;
			top: 12px;
			right: 13px;
		}
	}



	.product-labels {
		position: absolute;
		top: 5px;
		left: 0px;

		span {
			&.label {
				display: block;

				img {
					@media (max-width: $screen-xs-max) {
						width: 40px;
					}
				}
				svg {
					@media (max-width: $screen-xs-max) {
						width: 40px;
					}
				}
			}
			&.label-discount {
				background-color: #ea5b0c;
				font-size: 16px;
				margin-top: 5px;
				margin-left: 7px;
				border-radius: 50%;
				transform: rotate(-22.5deg);
				display: block;
				font-weight: bold;
				width: 58px;
				height: 58px;
				line-height: 58px;
				text-align: center;
				padding: 0 !important;
				img {
					@media (max-width: $screen-xs-max) {
						width: 40px;
					}
				}
			}
		}

		a:hover {
			text-decoration: none;
		}
	}


	@media (max-width: $screen-xs-max) {
		.product-item-original {
			padding: 0 10px;

			.overlay-actions {
				top: 135px;
			}
		}
	}

}

.slots,
.tab-content,
.related-products {
	@media (min-width: $screen-md-min) {
		.product-item {
			margin: 0 !important;
		}
	}
}

.no-gutter {
	@include no-gutter();

	&:last-child {
		.product-item {
			@media (min-width: $screen-md-min) {
				border-right: none;
			}
		}
	}
}

.tg-divider-horizontal {
	border-bottom: 1px solid #e6e6e6;
	width: 100%;
	position: absolute;
	bottom: 40px;
}

/* tg-divider-horizontal v zoznamoch v poslednom riadku sa nema zobrazovat */
.row-products:last-child,
.herb-list:last-child {
	div.tg-divider-horizontal {
		display: none !important;
	}
}

// specialny styl pre tabulku v detaile produktu - 3 kavy z rady MGS
table.mgs {
	color: #CCB593 !important;
	width: 100%;

	th {
		padding: 10px 18px 3px;
		text-align: left;
		font-size: 15px;
		border-right: 1px solid #CCB593;
		@media (min-width: $screen-md-min) {
			white-space: nowrap;
		}
	}
	td {
		padding: 3px 18px 10px;
		text-align: left;
		color: #CCB593 !important;
		border-right: 1px solid #CCB593;
	}
	&.intense {
		background-color: #9F2A57;
	}
	&.creme {
		background-color: #283F8B;
	}
	&.classic {
		background-color: #2E4E48;
	}
}