.navbar {
	border-radius: 0;
}
.navbar-header {
	a {
		display: inline-block;
		padding-top: 5px;
		font-size: 12px;
		color: #fff;

		i {
			display: block;
			font-size: 20px;
			padding-top: 5px;
		}

		img {
			display: block;
			margin: 0 auto;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

#tg-button-cart {
	position: relative;

	.tg-menu-cart-quantity {
		position: absolute;
		bottom: 3px;
		right: 10px;
		background-color: $brand-primary;
		color: #fff;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		line-height: 16px;
		font-size: 10px;
		text-align: center;
	}
}

.navbar1 {
	background-image: url(../../../static/img/bg-topbar.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	background-color: #242320;
	height: 70px;
	border: 0;
	margin: 0;
	font-size: 14px;

	.navbar-brand {
		padding: 0 15px;
		margin-left: 0 !important;
	}

	ul {
		line-height: 34px;
		height: 34px;
		margin-top: 18px;

		li {
			text-transform: uppercase;
			margin-left: 10px;
			font-size: 12px !important;

			a {
				color: #F4F2ED;

				&:hover {
					text-decoration: none;
				}

				i {
					margin-left: 5px;
					vertical-align: sub;
				}
			}

			&#tg-menu-cart {
				padding-right: 0;

				img {
					margin-left: 6px;
				}
			}
		}
	}

	.li-search {
		background: rgba(0, 0, 0, .3);
		border-radius: 4px;
		margin-left: 0;
		margin-right: 20px;

		input {
			background: transparent;
			border: 0;
			&.form-control {
				color: #aaa;
			}
		}



		.icon-search {
			background-color: transparent;
			border: none;

			i {
				color: #aaa;
			}
		}
	}

	.favorite-icon i {
		font-size: 1.8em;
	}
}

.navbar2 {
	background-color: #e0ddd6;
	z-index: 100;
	border: 0;
	margin: 0;
	min-height: 45px !important;

	.container {
		position: static;
	}

	hr {
		border-top: 1px solid #999 !important;
		float: left;
		width: 230px;
	}

	@media (min-width: $screen-lg-min) {
		hr {
			width: 270px;
		}
	}

	@media (min-width: $screen-md-min) {
		min-height: 45px;
		height: 45px;
	}

	.nav.navbar-nav {
		font-size: 16px;
		text-transform: uppercase;
		display: block;
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			margin-left: -15px;
		}

		.dropdown {
			position: static !important;

			&.active {
				background-color: #e0ddd6;
			}
		}

		.divider {
			height: 1px;
			margin: 9px 0;
			overflow: hidden;
			background-color: #e5e5e5;
		}

		> li {
			font-weight: 700;
			font-size: 16px;
			float: left;

			&:first-child {
				border-left: 1px solid #8d8b83;
			}

			a {
				color: #3f3a35;
				display: block;
				padding: 4px 5px 4px 20px;
				text-decoration: none;

				&:hover {
					background-color: #e0ddd6;
				}

				i {
					margin-left: 0;
				}
			}

			> a {
				padding: 15px 17px 10px;

				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					padding: 15px 3px 10px 3px;
					font-size: 13px;
				}

				&:hover, &:active, &:focus {
					background-color: #f4F2ed;
				}
			}

			&.active > a:hover {
				background-color: #f4F2ed;
			}
		}

		&.navbar-right {

			li {
				border: none;

				a {
					color: #4e4a4a;
					font-size: 11px;
					font-weight: 500;
					padding: 15px 12px 10px;

					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						padding: 15px 0 10px 8px;
					}
				}

				.dropdown-menu {
					padding: 20px 0px;

					a.spoznaj {
						text-align: center;
						padding: 40px 0 !important;
						margin: 0;

						span {
							display: block;
							font-size: 18px;
						}

						&:hover {
							background-color: #e0ddd6;
						}
					}
				}
			}

		}

	}

	.navbar-nav > .open > a {
		background-color: #e3ded1;
	}

	.navbar-nav {
		@media (max-width: $screen-xs-max) {
			margin-left: 0;
			margin-right: 0;
		}

		& > li {
			float: none;
			display: inline-block;

			@media (max-width: $screen-xs-max) {
				width: 100%;
				display: block;
			}
		}
	}

	.navbar-collapse {
		background-color: #e0ddd6;
	}
}

.navbar-default .navbar-nav > .active > a {
	background-color: #f4F2ed;
}


@media (min-width: $screen-sm-min) {
	.navbar3  {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.navbar-fixed-top {
		@include transition(transform, 250ms, ease-out);
	}
	.navbar-fixed-top .navbar-collapse {
		max-height: 100vh !important;
		min-height: 101vh;
		box-shadow: none;
	}

	.hidden-menubar {
		.navbar-fixed-top {
			@include transform(translateY(-$navbar-height - 4px));
		}

		&.header-bar-in {
			.navbar-fixed-top {
				@include transform(translateY(-$navbar-height - $header-bar-height-4r - 4px));
			}
		}
	}

	.navbar1, .navbar2 {
		display: none;
	}

	.navbar3 {

		background-color: #3F3A35 !important;
		border: none !important;

		.navbar-header {

			margin-bottom: 1px;

			.navbar-toggle {
				border: none;

				margin: 5px 5px 0 0;

				.animated-icon.open {
					.icon-bar:first-child {
						margin-top: 8px;
						@include transform(rotate(45deg));
					}
					.icon-bar:nth-child(2) {
						opacity: 0;
						margin-left: -60px;
					}
					.icon-bar:last-child {
						margin-top: -10px;
						@include transform(rotate(-45deg));
					}
				}

				.icon-bar {
					width: 30px;
					height: 2px;
					@include transform(rotate(0deg));
					@include transition(all, .25s, ease-in-out);

					+ .icon-bar {
						 margin-top: 6px;
					}
				}
			}

			.navbar-brand {
				padding: 0;
			}

			a {
				display: block;
				float: left;

				i {
					padding: 10px 10px;

					&.fa-search {
						padding-left: 0;
					}
				}
			}

			.dropdown {
				float: left;
			}

			#tg-button-cart,
			.icon-loupe,
			.icon-user {
				padding: 10px 6px;
			}

		}

		.navbar-collapse {
			background-color: #e0ddd6;
		}

		.dropdown-mobile-search {
			min-width: 300px;
			left: 0px !important;

			i {
				padding: 0;
			}
		}

		.nav.navbar-nav {

			margin-top: -1px;
			margin-bottom: 0;

			> li {
				border: none !important;
				position: relative;

				a {
					padding: 7px 22px;
					line-height: 35px;
					color: #3f3a35;
					font-size: 16px;
					text-transform: uppercase;
				}

				> a {
					border-top: 1px solid $nav-darker-grey;
					font-weight: 700;
				}

				.menu-expand {
					padding-left: 15px;
					padding-right: 15px;
					color: #3f3a35;
					position: absolute;
					top: 6px;
					right: 10px;
					transform: rotate(90deg);
				}

				&.open {
					& > a {
						background-color: $nav-darker-grey;
						font-weight: bold;
					}

					.menu-expand {
						top: 10px;
						transform: rotate(180deg);
					}
				}

				.dropdown-menu {
					padding: 0 !important;

					.yamm-content {
						padding: 14px 36px;
					}

					.row {
						margin-left: -4px;
						margin-right: -4px;
					}

					li a {
						padding-left: 17px;
						padding-right: 5px;
					}

					ul {
						padding: 5px 0;
						line-height: 21px;
					}
				}
			}

			&.menu-info {

				> li a {
					font-weight: normal;
				}
			}

			li {
				&.dropdown-header {
					display: flex;
					align-items: center;
					margin-top: 20px;
					border-bottom: 1px solid $nav-darker-grey;
					padding: 0 0 0 5px;
					margin-bottom: 5px;

					img {
						margin-bottom: 3px;
						margin-top: -5px;
						margin-right: 7px;
					}
				}
			}

			ul:first-child li.dropdown-header {
				margin-top: 0px;
			}
		}
	}
}

.full-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	z-index: 100;
	opacity: 0;
	pointer-events: none;

	@include transition(opacity, 150ms, ease-out);

	&.active {
		opacity: 0.6;
		pointer-events: auto;
	}
}

hr {
	border-top: 1px solid #8d8b83;
	margin-bottom: 10px;
	margin-top: 10px;
}

.menu-text-red {
	color: $brand-primary !important;
}
