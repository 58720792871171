.breadcrumb {
	background-color: transparent;
	font-size: 12px;
	margin-top: 20px;
	margin-bottom: 0;

	a {
		text-decoration: underline;
	}

	> li + li:before {
		color: #000;
	}
}
