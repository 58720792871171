.font-size-15 {
	font-size: 15px;
}

.font-bold {
	font-weight: 700;
}

.ml-20px {
	margin-left: 20px;
}

.mb-20px {
	margin-bottom: 20px;
}

.mr-10px {
	margin-right: 10px;
}

.d-flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-end {
	justify-content: flex-end;
}

.text-brand-primary {
	color: $brand-primary;
}

.ps-2 {
	padding-inline-start: 0.5rem;
}

.pe-2 {
	padding-inline-end: 0.5rem;
}
