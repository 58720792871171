.form-group {
	.alert {
		margin-bottom: 2px;

		&:last-child {
			margin-bottom: -15px;
		}
	}
}
.dropdown-mobile-search {
	.form-control {
		height: 39px;
	}
}
