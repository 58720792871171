@mixin transition($property, $duration, $timing-function, $delay: 0) {
	@if $delay == 0 {
		-webkit-transition: $property $duration $timing-function;
		-moz-transition: $property $duration $timing-function;
		-ms-transition: $property $duration $timing-function;
		transition: $property $duration $timing-function;
	} @else {
		-webkit-transition: $property $duration $timing-function $delay;
		-moz-transition: $property $duration $timing-function $delay;
		-ms-transition: $property $duration $timing-function $delay;
		transition: $property $duration $timing-function $delay;
	}
}
