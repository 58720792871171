a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}
#CookiebotWidget #CookiebotWidget-buttons button:hover,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:hover {
   background-color: #ac1528 !important;
   border-color: #ac1528 !important;
   opacity: 1 !important;
 }
