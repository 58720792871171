.navbar-nav>li>.dropdown-menu {
	background-color: #F4F2ED !important;
	left: 0;
	border-radius: 0 !important;
	border: none;
	padding: 20px 0;
	width: 100%;
	box-shadow: none;

	a {
		text-transform: uppercase;
		font-weight: 500;
		line-height: 20px;
	}

	ul {
		min-width: 250px;
		padding-left: 30px;
		padding-right: 30px;
	}

	li {
		font-size: 16px;
		clear: both;

		&.active {
			background-color: #e0ddd6;
		}

		&.dropdown-header {
			font-size: 14px;
			padding: 0;
			font-weight: 500;
			color: $text-color;
			font-family: $font-family-sans-serif;
			text-transform: none;

			img {
				vertical-align: bottom;
				margin-bottom: -7px;
			}
		}
	}


	@media (max-width: $screen-xs-max) {
		min-width: auto;

		ul {
			min-width: auto;
		}
	}

	&.col-menu-prislusenstvo,
	&.col-menu-pre-firmy,
	&.col-menu-spoznaj {

		li a {
			min-height: 250px;
			&:hover,
			&.active {
				background-color: #e0ddd6 !important;
			}

			hr {
				border-color: $nav-darker-grey;
			}

			span {
				margin-top: 20px;
				font-size: 16px;
				text-transform: uppercase;

				img {
					vertical-align: bottom;
				}
			}

			ul {
				margin-top: 27px;

				li {
					margin: 0;
					font-size: 14px;
					text-transform: none;
					display: block;
					line-height: 24px;
				}
			}
			@media (min-width: $screen-xs-min) and (max-width: $screen-md-max) {
				span {
					font-size: 13px;

					img {
						width: 30px !important;
					}
				}
				ul li {
					font-size: 14px;
				}
			}
		}

		@media (max-width: $screen-xs-max) {

			.col-xs-12 {
				padding-left: 0;
				padding-right: 0;

				& > a {
					padding-left: 7px !important;
				}
			}

			li a {
				ul {
					padding: 0 17px !important;
					margin-top: 0;

					li {
						display: inline;
						line-height: 21px;
						font-family: $font-family-sans-serif;
					}
				}

				hr {
					margin-top: 7px;
				}
			}
		}
	}
}
.navbar-nav.navbar-right > li > .dropdown-menu {
	left: auto;
}

.navbar1 {
	.btn-group ul.dropdown-menu {
		height: auto !important;
	}
	.btn.btn-default, .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
		color: #aaa;
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
		font-size: 12px;
	}
}
