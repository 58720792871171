body {
	margin-top: $body-margin-top-mobile;

	&.header-bar-in {
		margin-top: $body-margin-top-mobile + $header-bar-height-4r;
	}

}

// Text header-baru sa preformátuje na tri riadky.
@media (min-width: 408px) {
	body {
		&.header-bar-in {
			margin-top: $body-margin-top-mobile + $header-bar-height-3r;
		}
	}
}

// Text header-baru sa preformátuje na dva riadky.
@media (min-width: $screen-sm-min) {
	body {
		&.header-bar-in {
			margin-top: $body-margin-top + $header-bar-height-2r;
		}
	}
}

// Text header-baru sa preformátuje na jeden riadok.
@media (min-width: 1227px) {
	body {
		&.header-bar-in {
			margin-top: $body-margin-top + $header-bar-height-1r;
		}
	}
}

@media (min-width: $screen-sm-min) {
	body {
		margin-top: $body-margin-top;
	}
}

.tg-margin-top0 {
	margin-top: 0;
}
.tg-margin-top10 {
	margin-top: 10px;
}
.tg-margin-top15 {
	margin-top: 15px;
}
.tg-margin-top20 {
	margin-top: 20px;
}
.tg-margin-top25 {
	margin-top: 25px;
}
.tg-margin-top30 {
	margin-top: 30px;
}
.tg-margin-top40 {
	margin-top: 40px;
}
.tg-margin-top45 {
	margin-top: 45px;
}
.tg-margin-top50 {
	margin-top: 50px;
}
.tg-margin-top55 {
	margin-top: 55px;
}
.tg-margin-top60 {
	margin-top: 60px;
}
.tg-margin-top70 {
	margin-top: 70px;
}
.tg-margin-top80 {
	margin-top: 80px;
}
.tg-margin-top100 {
	margin-top: 100px;
}
.tg-margin-top130 {
	margin-top: 130px;
}
.tg-margin-bottom0 {
	margin-bottom: 0;
}
.tg-margin-bottom10 {
	margin-bottom: 10px;
}
.tg-margin-bottom20 {
	margin-bottom: 20px;
}
.tg-margin-bottom25 {
	margin-bottom: 25px;
}
.tg-margin-bottom30 {
	margin-bottom: 30px;
}
.tg-margin-bottom40 {
	margin-bottom: 40px;
}
.tg-margin-bottom50 {
	margin-bottom: 50px;
}
.tg-margin-bottom60 {
	margin-bottom: 60px;
}
.tg-margin-bottom70 {
	margin-bottom: 70px;
}
.tg-margin-bottom80 {
	margin-bottom: 80px;
}
.tg-margin-bottom100 {
	margin-bottom: 100px;
}

h1 {
	@include font-secondary();
	text-transform: uppercase;
	margin-top: 10px;
}

h2 {
	font-weight: 400;
}
hr {
	border-top: 1px solid #E5E6E3 !important;
}

.btn {
	text-transform: uppercase;

	&.btn-default {
		background-color: #ece8e6;
		color: #625a51;
	}
}
.btn-secondary {
	color: #fff;
	background-color: #625a51;
	border-color: #625a51;

	&:hover {
		color: #fff;
		background-color: #3f3a35;
	}

	&:focus {
		color: lighten(#625a51, 40%);
	}
}

.color-primary {
	color: $brand-primary;
}
.color-grey {
	color: rgba(204, 204, 204, .8) !important;
}
.text-success {
	color: #009933;
}
.bgcolor-primary {
	background-color: $brand-primary !important;
}
.font-secondary {
	@include font-secondary();
}


.tg-helptext {
	font-size: 85%;
}
.tg-biggerstar {
	font-size: 150%;
	line-height: 100%;
}
.tg-stock-error {
	color: red;
}
.text-uppercase {
	text-transform: uppercase;
}
.no-uppercase {
	text-transform: none;
}
.tg-product-list-text {
	font-size: 16px;
	line-height: 24px;
}
a.underline {
	text-decoration: underline !important;
}


.vertical-align {
	display: flex;
	align-items: center;
}

.text-xs-center {
	@media (max-width: $screen-xs-max) {
		text-align: center !important;
	}
}
.nowrap {
	white-space: nowrap;
}

.tg-shadow {
	-webkit-box-shadow: 0 1px 3px 0 rgba(34, 34, 34, .8);
	-moz-box-shadow: 0 1px 3px 0 rgba(34, 34, 34, .8);
	box-shadow: 0 1px 3px 0 rgba(34, 34, 34, .8);
}

.tg-product-of-month {
	background-image: url(../../../static/img/bg-spring-promo.jpg);
	background-position: top center;
	background-repeat: repeat;
	padding-top: 25px;
	padding-bottom: 25px;
	margin: 0;
	-moz-box-shadow:    inset  0  6px 6px -6px rgba(0, 0, 0, .2),
						inset  0 -6px 6px -6px rgba(0, 0, 0, .2);
	-webkit-box-shadow: inset  0  6px 6px -6px rgba(0, 0, 0, .2),
						inset  0 -6px 6px -6px rgba(0, 0, 0, .2);
	 box-shadow:        inset  0  6px 6px -6px rgba(0, 0, 0, .2),
						inset  0 -6px 6px -6px rgba(0, 0, 0, .2);

	img {
		margin-top: 5px;
		margin-bottom: 5px;
		width:100%;
	}

	.image-effect {
		position:relative;
		display: block;
		&:after {
			content:'\A';
			position:absolute;
			width:100%; height:100%;
			top:0; left:0;
			background:rgba(255, 255, 255,0.4);
			opacity:0;
			transition: all 0.5s;
			-webkit-transition: all 0.5s;
		}
		&:hover:after {
			opacity:1;
		}
	}
}

.divider-with-bg {
	text-align: center;
	color: $text-color;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	background-color: #F4F2ED;
	background-repeat: repeat;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 0;
	min-height: 40px;

	@media (max-width: $screen-xs-max) {
		font-size: 13px;
	}

	span.bold-shipping {
		font-weight: 700;
		padding-left: 5px;
	}

	&.divider-uvod {
		background-color: #fff;;
	}
	&.vianoce-terminy {
		background-color: $brand-primary;
		color: #fff;
		background-image: none;

		a {
			color: #fff;
			text-decoration: underline;
			text-transform: none;
		}
	}
}

#footer-line-progress {
	opacity: 0;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: $brand-primary;
	color: white;
	font-size: 16px;
	font-weight: 700;
	text-align: center;

	z-index: 100;
	@include transition(all, 500ms, ease-out);
	@include transform(translateY(130px));

	@media (min-width: $screen-sm-min) {
		.container-fluid {
			margin-top: 15px;
			margin-bottom: 15px;
			font-size: 18px;
		}
	}

	&.in {
		opacity: 1;
		@include transform(translateY(0));
	}

	.row {
		& > div {
			padding-top: 5px;
			padding-bottom: 0;

			&:first-child {
				padding-top: 10px;
			}

			&:last-child {
				padding-bottom: 10px;
			}
		}

		@media (min-width: $screen-sm-min) {
			& > div {
				padding-top: 0;
				padding-bottom: 0;

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	&.success {
		background-color: #0d8241;

		.progress {
			background-color: #084d26;
		}
	}

	.progress {
		height: 9px;
		background-color: #752725;
		margin-bottom: 0;
		margin-top: 0;
		@include transition(all, 500ms, ease-out);

		@media (min-width: $screen-sm-min) {
			margin-top: 9px;
		}

		.progress-bar {
			background-color: #fff;
		}
	}

	.close {
		position: absolute;
		right: 12px;
		top: 10px;
		z-index: 100;
		color: white;
		opacity: .6;
	}
	.progress-info-text {
		font-size: 12px;
		font-weight: 400;

		@media (min-width: $screen-sm-min) {
			font-size: 16px;
		}
	}

	.fa-check {
		padding-right: 5px;
	}
}

.main-title {
	text-align: center;
}


/* sekcia newsletter */
/*
.newsletter-item {
	position: relative;
	height: 170px;
	min-height: 170px;
	overflow: hidden;
	border-right: 1px solid #e6e6e6;

	h2 {
		margin: 0;
		font-size: 20px;
		max-height: 88px;
		overflow: hidden;

		@media (max-width: $screen-xs-max) {
			font-size: 16px;
		}
	}

	.buttons {
		position:absolute;
		bottom: 0;
		left:50%;
		transform:translate(-50%, 0);
		width:50%;
	}
}
*/

/* shopping cart and checkout process */
.tg-cart {
	.tg-cart-price-total {
		font-size: 22px;
		font-weight: 700;
	}

	tfoot {
		border-top: 1px solid #ddd;

		td {
			border: none !important;
		}
	}
}

.btn-checkout {
	font-size: 12px;
	padding: 6px 8px;
}

h2.cart-proces,
h2.account {
	font-size: 24px;
}

footer {
	background-size: auto;
	font-weight: 400;
	background-color: #EAF1AD;
	background-image:
		//url(../../../static/img/easter-grass.png),
		url(../../../static/img/bg-easter-footer.jpg);
	background-position:
		//bottom center,
		top center;
	background-repeat: no-repeat;

	@media (min-width: $screen-lg-min) {
		background-image:
			//url(../../../img/easter-grass.png),
			//url(../../../img/easter-butterfly.png),
			url(../../../static/img/bg-easter-footer.jpg);

		background-position:
			//bottom center,
			//center 80%,
			top center;

		background-repeat: no-repeat;
	}

	a, h3 {
		color: $text-color;
	}

	h3 {
		margin: 25px 0 10px;
		padding: 0;
		line-height: 24px !important;
		font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;

		@media (max-width: $screen-xs-max) {
			margin: 20px 0 7px 0;
		}
	}

	a:hover {
		color: #000;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		line-height: 23px !important;
	}
	.column-with-border {
		@media (min-width: $screen-sm-min) {
			border-right: 1px solid #E1E1E1;
			border-left: 1px solid #E1E1E1;
			min-height: 188px;
		}

		.footer-midtitle {
			margin-top: 40px;

			@media (max-width: $screen-xs-max) {
				margin-top: 20px;
			}
		}

		.form-control {
			border: none;
		}
	}

	.btn {
		&.btn-footer {
			padding: 8px 22px 9px;
			color: #d4d2c6;
			background-color: #3f3a35 !important;
			border: 0 !important;
		}
	}

	.footer-icons {
		p {
			line-height: 18px;
		}

		.footer-contact-icons {
			i {
				vertical-align: middle;
				padding-right: 10px;
			}
		}

		.footer-social-icons {
			clear: both;

			li i {
				vertical-align: middle;
				padding-right: 9px;
				padding-bottom: 7px;
				font-size: 33px;
			}

			> i {
				margin: 10px 10px 10px 0;
				float: left;

				@media (max-width: $screen-xs-max) {
					float: none;
				}
			}
		}
	}

	.tg-footer {
		padding-top: 20px;
		padding-bottom: 40px;
	}
	.logo-bop {
		margin-top: -15px;
	}

	@media (max-width: $screen-xs-max) {
		text-align: center;

		.logo-bop {
			margin-top: 15px;
		}
		.column-with-border {
			margin-bottom: 30px;
			margin-top: 30px;
		}

		.tg-footer {
			padding-top: 0;
		}

	}
}

.tg-footer-copy {
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;
	font-size: 12px;
	color: #d4d2c6;
	background-color: #3f3a35;

	.container {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	a {
		color: #d4d2c6;
		text-decoration: underline;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 12px;
	}
}

/* modalne okna */

.modal-body {
	h2 {
		line-height: 22px;
		font-size: 20px;
	}
	//.product-item-price {
	//	font-size: 25px;
	//	margin: 15px 0 0;
	//}
}
.modal-footer {
	text-align: center;

	.btn {
		margin-bottom: 10px !important;
	}
}

#newsletter-modal,
#newsletterListModal {
	.modal-title {
		text-transform: uppercase;
		font-size: 26px;
		margin-top: 25px;
	}
}

#newsletter-mobile {
	position: fixed;
	bottom: -250px;
	width: 100%;
	background-color: #fff;
	z-index: 1000;
	border-top: 1px solid #e6e6e6;
	height: 250px;
	@include transition(bottom, 600ms, linear);

	&.show {
		bottom: 0;
	}

	h2 {
		text-transform: uppercase;
		font-size: 20px;
		margin-top: 25px;
	}
}
@media (max-width: $screen-xs-max) {
	.text-center-xs {
		text-align: center;
	}
}

//.video-container {
//	position: relative;
//	padding-bottom: 56.25%;
//	padding-top: 83px; height: 0; overflow: hidden;
//}
//
//.video-container iframe,
//.video-container object,
//.video-container embed {
//	position: absolute;
//	top: 0;
//	left: 0;
//	width: 100%;
//	height: 100%;
//}

/* modalne okno pre youtube na uvode */
.modal-youtube {
	.modal-header {
		border: 0;
	}
	.modal-content {
		background-color: transparent !important;
		border: 0;
		box-shadow: none;
	}
	.modal-dialog {
		@media (min-width: $screen-sm-min) {
			width: 80vw;
			margin: 0 auto;
		}
	}
	.video-responsive {
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
		height: 0;

		iframe {
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}
	.close {
		color: #fff;
		opacity: 1;
	}
}

/* heureka widget sa nezobrazuje na xs */
@media (max-width: $screen-xs-max) {
	#heurekaTableft {
		display: none !important;
	}
}

/* sutaze tlacitko v menu */
.btn-sutaze {
	background-color: #ffcb00 !important;
	color: $brand-primary !important;
	font-weight: 700 !important;
	font-size: 14px !important;

	@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
		font-size: 11px !important;
		letter-spacing: normal !important;
		padding: 15px 5px 10px 5px !important;
	}
}

.product-extra-section {
	@include font-secondary();
	margin-top: 0;

	@media (min-width: $screen-lg-min) {
		margin-top: 40px;
	}

	h2.blog-title {
		font-size: 18px;
		line-height: 20px;
		padding-top: 0;
		margin-top: 15px;
		margin-bottom: 0;
		overflow: hidden;
		font-weight: 700;
		max-height: 80px;
		font-family: $font-family-sans-serif;

		a {
			color: $text-color;
		}
	}

	.blog-date {
		display: inline-block;
		font-size: 12px;
		line-height: 12px;
		font-family: $font-family-sans-serif;
	}

	.desc {
		margin-top: 10px;
		font-family: $font-family-sans-serif;
		height: 150px;
		max-height: 150px;
		overflow: hidden;
	}

	.blog-widget {
		height: 378px;
		max-height: 441px;
		margin-bottom: 25px;
		overflow: hidden;

		p {
			line-height: 21px;
		}
	}
}


