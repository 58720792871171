.banner-top {
	text-align: center;
	z-index: 10;
	line-height: 0;
	position: relative;

	img {
		max-width: 100%;
	}

	.btn-slider {
		position: absolute;
		left: 50%;
		bottom: 40px;
		transform: translate(-50%, 0);
		font-size: 16px;
		line-height: 18px;
		padding-top: 8px;
		padding-left: 15px;
		padding-right: 15px;
		@include font-secondary();
		text-transform: none;

		@media (min-width: $screen-md-min) {
			font-size: 21px;
			line-height: 22px;
			padding-top: 10px;
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	#slider-main,
	#slider-main1,
	#slider-main2,
	#slider-main3 {
		display: inline-block;
		z-index: 80;

		&:hover {
			.carousel-control {
				display: block;
			}
		}

		&.carousel-fade {
			.carousel-inner {

				.item {
					transition-property: opacity;
				}

				.item,
				.active.left,
				.active.right {
					opacity: 0;
				}

				.active,
				.next.left,
				.prev.right {
					opacity: 1;
				}

				.next,
				.prev,
				.active.left,
				.active.right {
					left: 0;
					transform: translate3d(0, 0, 0);
				}
			}
		}

		.carousel-indicators {
			bottom: 5px;
		}

		.carousel-control {
			display: none;
			background-repeat: no-repeat;
			background-image: none;
			background-color: rgba(0, 0, 0, .5);
			width: 50px;
			opacity: 1;
			filter: unset;

			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.banner-top {
	&.banner-store {
		.carousel-indicators {
			bottom: -45px !important;

			li {
				border: 1px solid #3f3a35 !important;
			}

			.active {
				background-color: #3f3a35;
			}
		}

		#slider-main1 .carousel-control,
		#slider-main2 .carousel-control,
		#slider-main3 .carousel-control {
			background-color: transparent !important;
			filter: alpha(opacity=90);
			&.right {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
			}
			&.left {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
			}

			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

