.home-tabs {
	text-align: center;
	color: #3a3a3a;
	font-size: 14px;
	text-transform: uppercase;
	background-color: #F4F2ED;
	width: 100%;

	.nav > li > a {
		padding: 15px;
		color: $text-color;
		border: 0 !important;

		&:hover {
			background-color: rgba(255, 255, 255, .4);
		}
	}

	.nav-tabs {
		border: 0 !important;
	}

	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		background-color: #fff;
		border: 0 !important;
	}

	#recently_viewed {
		min-height: 400px;
	}
}

.tab-content {
	#recently_viewed {
		min-height: 400px;
	}
}
