.fancy-header {
	max-width: 100%;
	overflow: hidden;
	margin-top: 0px;

	.fancy {
		text-align: center;
		margin-bottom: 15px;
		margin-top: 15px;
		@include font-secondary();
		font-size: 24px;
	}

	h1.fancy {
		font-size: 26px;
		line-height: 40px;
		overflow: hidden;
	}

	span {
		display: inline-block;
		position: relative;

		&:before,
		&:after {
			content: "";
			position: absolute;
			height: 5px;
			border-bottom: 1px solid #E5E6E3;
			top: 13px;
			width: 600px;
		}

		&:before {
			right: 100%;
			margin-right: 60px;
		}

		&:after {
			left: 100%;
			margin-left: 60px;
		}
	}
}
