#header-bar {
	background-color: #CD972A;
	color: #ffffff;
	font-size: 16px;
	line-height: 17px;
	text-align: center;
	padding: 10px 0 10px;

	small {
		display: inline-block;
		font-size: 14px;
		padding-top: 0;
	}

	a {
		color: #ffffff;
		text-decoration: underline;
	}

	@media (min-width: $screen-sm-min) {
		font-size: 15px;
		padding: 12px 0 11px;
		small {
			font-size: 15px;
			padding-top: 0;
		}
	}
}

