.spinner {
	&.input-group {
		& > .form-control {
			@media (min-width: $screen-sm-min) {
				width: 60px;
				text-align: center;
			}
		}
	}
}
